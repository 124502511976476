import React from 'react'
import PageContact from '../components/pages/Contact/Contact'
import Layout from '../Layouts/zh-hk'


const contact = ({ location }) => {
  return (
    <Layout location={location}>
      <PageContact />
    </Layout>
  )
}

export default contact

